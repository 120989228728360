import React, { useState } from 'react';

const OLD_ROSE = '990033';
const TRIANGLE_GRAY = '999999';

interface ColorBoxProps {
  color: string;
  onClick: () => void;
  isSelected: boolean;
}

const ColorBox: React.FC<ColorBoxProps> = ({ color, onClick, isSelected }) => (
  <div
    onClick={onClick}
    style={{
      backgroundColor: color,
      width: '80px',
      height: '80px',
      margin: '5px',
      border: isSelected ? '5px solid black' : '1px solid gray',
      cursor: 'pointer',
    }}
  ></div>
);

const generateColorVariations = (hex: string, count: number): string[] => {
  const base = parseInt(hex.replace('#', ''), 16);
  return Array.from({ length: count }, (_, i) => {
    const variation = base + i * 0x050505; // Adjusts RGB values to create variation
    return `#${Math.min(variation, 0xffffff).toString(16).padStart(6, '0')}`;
  });
};

const shuffle = (array: string[]): string[] => {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    
    return array;
};

const ColorGuesser: React.FC = () => {
  const [oldRoseColors, setOldRoseColors] = useState(shuffle(generateColorVariations(OLD_ROSE, 5)));
  const [triangleGrayColors, setTriangleGrayColors] = useState(shuffle(generateColorVariations(TRIANGLE_GRAY, 5)));
  const [selectedOldRoseIndex, setSelectedOldRoseIndex] = useState<number | null>(null);
  const [selectedTriangleGrayIndex, setSelectedTriangleGrayIndex] = useState<number | null>(null);
  const [result, setResult] = useState<string | null>(null);

  const handleSubmit = () => {
    if (selectedOldRoseIndex === null || selectedTriangleGrayIndex === null) {
      alert('Please select one box from each color row.');
      return;
    }

    const isCorrectOldRose = oldRoseColors[selectedOldRoseIndex] === '#' + OLD_ROSE;
    const isCorrectTriangleGray = triangleGrayColors[selectedTriangleGrayIndex] === '#' + TRIANGLE_GRAY;

    if (isCorrectOldRose && isCorrectTriangleGray) {
      setResult('You passed!');
    } else {
      setResult('Try again!');
    }

    // Regenerate colors for a new round
    setOldRoseColors(shuffle(generateColorVariations(OLD_ROSE, 5)));
    setTriangleGrayColors(shuffle(generateColorVariations(TRIANGLE_GRAY, 5)));
    setSelectedOldRoseIndex(null);
    setSelectedTriangleGrayIndex(null);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{marginBottom: '0px'}}>Triangle Color Guesser</h1>
      <h2 style={{marginTop: '0px'}}>Stairs - Fall 2024</h2>
      <div>
        <h2>Old Rose</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {oldRoseColors.map((color, index) => (
            <ColorBox
              key={index}
              color={color}
              onClick={() => setSelectedOldRoseIndex(index)}
              isSelected={selectedOldRoseIndex === index}
            />
          ))}
        </div>
      </div>
      <div>
        <h2>Triangle Gray</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {triangleGrayColors.map((color, index) => (
            <ColorBox
              key={index}
              color={color}
              onClick={() => setSelectedTriangleGrayIndex(index)}
              isSelected={selectedTriangleGrayIndex === index}
            />
          ))}
        </div>
      </div>
      <button onClick={handleSubmit} style={{ marginTop: '20px', padding: '10px 20px' }}>
        Submit
      </button>
      {result && <h2>{result}</h2>}
    </div>
  );
};

export default ColorGuesser;
